<template>
  <TransitionRoot :show="open" as="template" appear>
    <Dialog class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-0 transition-all"
          >
            <Combobox v-model="selected" @update:modelValue="onSelect">
              <div class="grid grid-cols-1">
                <ComboboxInput
                  class="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 text-base text-gray-900 outline-none border-none placeholder:text-gray-400 sm:text-sm"
                  placeholder="Search…"
                  @change="handleSearch"
                />
                <MagnifyingGlassIcon
                  class="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400"
                  aria-hidden="true"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || filteredDocuments.length > 0"
                static
                as="ul"
                class="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
              >
                <li class="p-2">
                  <h2
                    v-if="query === ''"
                    class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500"
                  >
                    Recent searches
                  </h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption
                      v-for="item in query === '' ? recent : filteredDocuments"
                      :key="item.id"
                      :value="item"
                      as="template"
                      v-slot="{active}"
                    >
                      <li
                        :class="[
                          'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                          active && 'bg-blue-600 text-white outline-none border-none'
                        ]"
                        @click="onSelect(item)"
                      >
                        <FolderIcon
                          :class="[
                            'size-6 flex-none',
                            active ? 'text-white forced-colors:text-[Highlight]' : 'text-gray-400'
                          ]"
                          aria-hidden="true"
                        />
                        <span class="ml-3 flex-auto truncate"
                          ><span class="font-semibold">{{ item.original_name }}</span
                          ><span>, {{ item.sender_name }}</span></span
                        >
                        <span v-if="active" class="ml-3 flex-none text-blue-100"> Jump to... </span>
                        <span v-if="item.similarity_score" class="ml-2 text-xs text-gray-400">
                          ({{ Math.round(item.similarity_score * 100) }}%)
                        </span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
                <li v-if="query === ''" class="p-2">
                  <h2 class="sr-only">Quick actions</h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption
                      v-for="action in quickActions"
                      :key="action.shortcut"
                      :value="action"
                      as="template"
                      v-slot="{active}"
                    >
                      <li
                        :class="[
                          'flex cursor-default select-none items-center rounded-md px-3 py-2',
                          active && 'bg-blue-600 text-white outline-none border-none'
                        ]"
                      >
                        <component
                          :is="action.icon"
                          :class="[
                            'size-6 flex-none',
                            active ? 'text-white forced-colors:text-[Highlight]' : 'text-gray-400'
                          ]"
                          aria-hidden="true"
                        />
                        <span class="ml-3 flex-auto truncate">{{ action.name }}</span>
                        <span
                          :class="[
                            'ml-3 flex-none text-xs font-semibold',
                            active ? 'text-blue-100' : 'text-gray-400'
                          ]"
                        >
                          <kbd class="font-sans">⌘</kbd>
                          <kbd class="font-sans">{{ action.shortcut }}</kbd>
                        </span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-if="query !== '' && filteredDocuments.length === 0"
                class="px-6 py-14 text-center sm:px-14"
              >
                <DocumentDuplicateIcon class="mx-auto size-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 text-sm text-gray-500">
                  We couldn't find any items with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid';
import {
  DocumentDuplicateIcon,
  DocumentPlusIcon,
  FolderIcon,
  FolderPlusIcon,
  HashtagIcon
} from '@heroicons/vue/24/outline';
import {debounce} from 'lodash-es';
import {storeToRefs} from 'pinia';

const documentStore = useDocumentStore();
const {filteredDocuments} = storeToRefs(documentStore);

// Define props
const props = defineProps({
  show: Boolean
});

const toggleModal = () => {
  open.value = !open.value;
};

defineExpose({
  toggleModal
});

// Define reactive variables
const open = ref(props.show);
const selected = ref(null);
const query = ref('');
const recent = ref([]);

// Define constants
const quickActions = [
  {name: 'Add new document…', icon: DocumentPlusIcon, shortcut: 'N', url: '#'},
  {name: 'Add new sender…', icon: FolderPlusIcon, shortcut: 'S', url: '#'},
  {name: 'Add tag…', icon: HashtagIcon, shortcut: 'T', url: '#'}
];

// Create debounced search function
const debouncedSearch = debounce(async searchQuery => {
  if (searchQuery.trim()) {
    try {
      const results = await documentStore.searchDocuments(searchQuery);
      console.log('Search results:', results); // For debugging
    } catch (error) {
      console.error('Error searching documents:', error);
    }
  }
}, 300);

// Handle search input changes
const handleSearch = event => {
  const value = event.target.value;
  query.value = value;
  if (value) {
    debouncedSearch(value);
  } else {
    documentStore.setFilteredDocuments([]);
  }
};

// Watch for modal visibility
watch(
  () => props.show,
  newVal => {
    open.value = newVal;
    if (!newVal) {
      query.value = '';
      selected.value = null;
    }
  }
);

function onSelect(item) {
  if (item) {
    console.log('Selected document:', item);
    navigateTo({path: `/app/documents/${item.id}`});
    open.value = false;
  }
}
</script>
